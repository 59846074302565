.selectWrapper {
  position: fixed;
  top: 20px;
  left: 10px;
  right: 19px;
  z-index: 1;
  display: flex;
  justify-content: flex-start;
}

.selectWrapper > div {
  margin-left: 10px;
  width: 200px;
}

.paginationWrapper {
  // position: fixed;
  bottom: 15px;
  right: 15px;
  margin-top:20px !important
}


.filterCurrency {
  border: 1px solid #ccc;
  height: 30px;
  border-radius: 3px;
  font-size: 15px;
  margin-left: 10px;
}

.filterCurrency:focus {
  outline: none;
}