.table {
  margin-bottom: 0px !important;
}

.show__clickable:hover {
  text-decoration: underline;
  }
  

  .MuiDialog-paperScrollPaper {
    height: 500px;
    width: 400px;
  }